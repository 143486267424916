<template>
  <div>
    <h5 class="text-main-gradient-secondary"><span class="material-icons ">power_settings_new</span> Active/Inactive
      sensors</h5>
    <p>This chart shows last 60 days sensor activity</p>
    <apexchart ref="chart" type="area" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import axios from "axios";
  var config = require("@/config/api.json");
  import moment from "moment";
  const API = config.api;

  export default {
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        currentSensors: [],
        // Datos para la gráfica de área
        series: [
          {
            name: 'Active users',
            data: []
          },
          {
            name: 'Inactive users',
            data: []
          }
        ],
        chartOptions: {
          colors: ['#33FF57', '#807e7e'],
          chart: {
            height: 350,
            type: 'area'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'category', // Cambia a 'category' para formato de fechas sin timestamp
            labels: {
              format: 'yyyy-MM-dd' // Formato en el eje `x` 
            }
          },
          tooltip: {
            x: {
              format: 'dd/MM/yyyy' // Mantiene el formato en el tooltip
            }
          },
        },
      };
    },
    mounted() {
      this.managerCurrentSensors();
      setInterval(() => {
        this.managerCurrentSensors();
      }, 60000); 
    },
    methods: {
      async managerCurrentSensors() {
        try {
          const response = await axios.get(`${API}/manager-get-sensor-status`);
          this.currentSensors = response.data;
          const activeSeries = {
            name: 'Active sensors',
            data: []
          };
          const InactiveSeries = {
            name: 'Inactive sensors',
            data: []
          };

          const categories = new Set();
          const activeDataMap = {};
          const inactiveDataMap = {};


          if (this.currentSensors.active) {
            this.currentSensors.active.forEach(sensor => {
              if (sensor.total_records !== undefined) {
                activeSeries.data.push(sensor.total_records);
                const formattedDate = new Date(sensor.date_used).toISOString().split('T')[0];
                activeDataMap[formattedDate] = sensor.total_records;
                categories.add(formattedDate);
              } else {
                console.error('total_records is undefined for active sensor:', sensor);
              }
            });
          } else {
            console.error('this.currentSensors.active is undefined');
          }

          if (this.currentSensors && this.currentSensors.inactive) {
            this.currentSensors.inactive.forEach(sensor => {
              if (sensor.total_records !== undefined && sensor.date_used !== undefined) {
                InactiveSeries.data.push(sensor.total_records);
                const formattedDate = new Date(sensor.date_used).toISOString().split('T')[0];
                inactiveDataMap[formattedDate] = sensor.total_records;
                categories.add(formattedDate);
              } else {
                console.error('total_records or date_used is undefined for inactive sensor:', sensor);
              }
            });
          } else {
            console.error('this.currentSensors.inactive is undefined or this.currentSensors is undefined');
          }

          const sortedCategories = Array.from(categories).sort();
          const activeSeriesData = [];
          const inactiveSeriesData = [];

          sortedCategories.forEach(date => {
            activeSeriesData.push({ x: date, y: activeDataMap[date] || 0 });
            inactiveSeriesData.push({ x: date, y: inactiveDataMap[date] || 0 });
          });

          this.series = [
            { name: 'Active sensors', data: activeSeriesData },
            { name: 'Inactive sensors', data: inactiveSeriesData }
          ];

        } catch (error) {

          this.notify('error', 'There are no sensors to show');
        }
      }
    }
  };
</script>

<style scoped>
  /* Puedes agregar estilos personalizados aquí */
</style>