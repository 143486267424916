<template>
  <div>
    <p><strong>User Tiers</strong></p>
    <a class="text-link smallText" @click="openAddTier()">
      <span class="material-icons smallText me-1">playlist_add</span>Add tier</a>
    <vs-table>
      <template #thead>
        <vs-tr>
          <vs-th sort @click="userTiers = $vs.sortData($event, userTiers, 'tierName')">
            Tier
          </vs-th>
          <vs-th sort @click="userTiers = $vs.sortData($event, userTiers, 'startDate')">
            Start date
          </vs-th>
          <vs-th sort @click="userTiers = $vs.sortData($event, userTiers, 'endDate')">
            End Date
          </vs-th>
          <vs-th sort @click="
              userTiers = $vs.sortData($event, userTiers, 'remainingDays')
            ">
            Remaining Days
          </vs-th>
          <vs-th> Operations </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr :key="index" v-for="(item, index) in $vs.getPage(userTiers, page, max)" :data="item">
          <vs-td>
            <strong>{{ item.tierName }}</strong> <br />
            <p class="smalText">
              📅 {{ item.tierMode }} <br />
              <template v-if="parseInt(item.status) == 1">
                <span class="smallText badge bg-ready text-white">
                  {{ item.statusDesc }}
                </span>
              </template>
              <template v-if="parseInt(item.status) == 2">
                <span class="smallText badge bg-success text-white">
                  {{ item.statusDesc }}
                </span>
              </template>
              <template v-if="parseInt(item.status) == 3">
                <span class="smallText badge bg-danger text-white">
                  {{ item.statusDesc }}
                </span>
              </template>
            </p>
          </vs-td>
          <vs-td>{{ moment(item.startDate).utc().format("ll") }}</vs-td>
          <vs-td>{{ moment(item.endDate).utc().format("ll") }}</vs-td>
          <vs-td>{{ item.remainingDays }} days</vs-td>
          <vs-td>
            <template v-if="parseInt(item.status) == 1">
              <a class="text-link smallText" @click="showConnectTier(item)"><span
                  class="material-icons smallText me-1">cable</span>Connect</a>
              <br />
              <a class="text-link smallText" @click="showEditTier(item)"><span
                  class="material-icons smallText me-1">edit</span>Edit Dates</a>
              <br />
              <a href="" class="text-link"></a>
              <a class="text-link smallText" @click="showDeleteTier(item)"><span
                  class="material-icons smallText me-1">delete</span>Delete</a>
            </template>

            <template v-if="parseInt(item.status) == 2">
              <!-- <a class="text-link smallText" @click="showConnectTier(item)"><span
                  class="material-icons smallText me-1">search</span>Display Data</a>
              <br /> -->
              <a class="text-link smallText" @click="showEditTier(item)"><span
                  class="material-icons smallText me-1">edit</span>Edit Dates</a>
            </template>

          </vs-td>
        </vs-tr>
      </template>
      <template #footer>
        <vs-pagination v-model="page" :length="$vs.getLength(userTiers, max)" />
      </template>
    </vs-table>

    <!-- Dialogo para agregar un Tier nuevo al usuario -->
    <vs-dialog prevent-close not-center v-model="addTier">
      <template #header>
        <h4 class="not-margin">Tier <b>management</b></h4>
      </template>
      <div class="con-content">
        <div class="container-fluid">
          <div class="row" v-if="tiersOn">
            <div class="col-md-12">
              <p>Select a tier for the user.</p>
            </div>
            <div class="col-md-4">
              <span class="text-main-gradient-secondary">Billed annually</span>
            </div>
          </div>
          <div class="row" v-if="tiersOn">
            <div class="col-md-4 d-flex mt-3" v-for="(item, index) in tiers" :key="index">
              <div class="card main-card h-100">
                <div class="card-body">
                  <p class="text-main-gradient">{{item.package_name}}</p>
                  <template v-if="billed">
                    <h3>${{item.cost_yearly}} <small class="text-muted">/yearly</small></h3>
                  </template>
                  <template v-else>
                    <h3>${{item.cost_monthly}} <small class="text-muted">/monthly</small></h3>
                  </template>
                  <hr>
                  <p>
                    <strong>Features</strong> <br> <br>
                    <span class="smallText mb-1 d-flex align-items-center justify-content-left"
                      v-for="(item2, index2) in item.features" :key="index2">
                      <div class="circle me-1"></div> {{item2.feature_name}}
                    </span>
                  </p>
                </div>
                <div class="card-footer">

                  <template v-if="billed">
                    <vs-button @click="selectTier(item.package_id, item.cost_yearly, item.package_name, 'yearly')"
                      block>Add
                      {{item.package_name}}</vs-button>
                  </template>
                  <template v-else>
                    <vs-button @click="selectTier(item.package_id, item.cost_monthly, item.package_name, 'monthly')"
                      block>Add {{item.package_name}}</vs-button>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="selectedOn">
            <div class="col-md-12">
              <h4>Confirm selcted Tier</h4>
              <p>Please check that the data of the tier you have chosen is correct.</p>
              <div class="card main-card">
                <div class="card-body">
                  <p>
                    <strong>Tier:</strong> <span class="float-end">{{selectedTierName}}</span><br>
                    <strong>Periodicity:</strong> <span class="float-end">{{selectedTierTime}}</span><br>
                    <hr>
                    <strong>TOTAL:</strong> <span class="float-end">${{selectedTierCost}}</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-5 text-center">
              <vs-button @click="processPayment()" block>Confirm & Save</vs-button>
              <a class="text-link smallText text-center" @click="backToTier()">Return</a>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>


    <!-- Dialogo para eliminar con un confirm el selectedTierEdits -->
    <vs-dialog not-center v-model="showDeleteTierDialog">
      <template #header>
        <h4 class="not-margin">Delete <b>tier</b></h4>
      </template>
      <div class="con-content">
        <p>
          Are you sure you want to delete <strong>{{selectedTierdits.tierName }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteTier()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="showDeleteTierDialog = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Dialogo para editar los datos de un Tier -->
    <vs-dialog not-center v-model="showEditDialog">
      <template #header>
        <h4 class="not-margin">Edit <b>tier</b></h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>Change dates of {{selectedTierdits.tierName}}</p>
            <p class="smallText">
              Start date
              <input type="date" class="form-control mt-2" v-model="formattedStartDate" />
              End date
              <input type="date" class="form-control mt-2" v-model="formattedEndDate" />
            </p>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editTier()" transparent> Save changes</vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="showEditDialog = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>


    <!-- Dialogo para asignar a granja o station -->
    <vs-dialog not-center v-model="showStationDialog">
      <template #header>
        <h4 class="not-margin">Connect <b>tier</b></h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>Let's configure the tier</p>
            <p>
              <strong>Tier:</strong> {{loadTier.tierName}}<br>
              <!-- <strong>Features: </strong> <br>
              <span v-for="(item, index) in loadTier.features" :key="index">
                -{{item.feature_name}} <br>
              </span> -->
            </p>
            <div class="card main-card">
              <div class="card-body">
                <!-- <div class="form-group">
                  <span class="text-muted mt-3">1.- Select your tier:</span>
                  <select class="form-control" v-model="tierSelected">
                    <option value="0" selected>--Select your tier--</option>
                    <option :value="loadTier.package_id">{{loadTier.package_name}}</option>
                  </select>
                </div> -->
                <span class="text-muted mt-3">Create a domain/station name. </span>
                <input type="text" class="form-control" v-model="farmName" placeholder="E.g. Agtlink main farm">
                <p class="smallText">Domain name is the main area where the tier will exist, domain name can
                  be the name of cient company or some subdivision of it.</p>

                <div class="form-group mt-2">
                  <span class="text-muted mt-3">Storage Path</span>
                  <input type="text" class="form-control mr-1" placeholder="Select a Path" v-model.trim="storage_path"
                    maxlength="500" />
                  <p class="text-muted">Storage path must be selected in the desktop app.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5 text-center">
            <vs-button @click="continue1()" block>Save changes</vs-button>
          </div>
        </div>
      </div>
    </vs-dialog>


  </div>
</template>

<script>
import axios from "axios";
  import moment from "moment";
  var config = require("@/config/api.json");
  const API = config.api;
  export default {
    watch: {
      billed(value) {
        if (value) {
          this.billTime = "annnually";
          this.tier1 = 600;
          this.tier2 = 1200;
          this.tier2p = 1600;
        } else {
          this.billTime = "monthly";
          this.tier1 = 50;
          this.tier2 = 100;
          this.tier2p = 160;
        }
      },
    },
    props: {
      userId: {
        type: Number,
        required: true,
      },
      uid: {
        type: String,
        required: true,
      },
    },
    computed: {
      formattedStartDate: {
        get() {
          return this.startDateEdit ? this.formatDate(new Date(this.startDateEdit)) : '';
        },
        set(value) {
          this.startDateEdit = value ? new Date(value).toISOString().split('T')[0] : null;
        }
      },
      formattedEndDate: {
        get() {
          return this.endDateEdit ? this.formatDate(new Date(this.endDateEdit)) : '';
        },
        set(value) {
          this.endDateEdit = value ? new Date(value).toISOString().split('T')[0] : null;
        }
      }
    },
    data() {
      return {
        userTiers: [],
        page: 1,
        max: 10,
        tiers: [],
        tier1: 50,
        tier2: 100,
        tier2p: 160,
        billed: true,
        tiersOn: true,
        selectedOn: false,
        tierOn: false,
        selectedTierId: null,
        selectedTierCost: null,
        selectedTierName: null,
        selectedTierTime: null,
        loadTier: [],
        addTier: false,
        selectedTierdits: [],
        showDeleteTierDialog: false,
        showEditDialog: false,
        startDateEdit: null,
        endDateEdit: null,
        showStationDialog: false,
        storage_path: "%USERPROFILE%\\Desktop",
        farmName: null,
      };
    },
    mounted() {
      console.log(this.userId);
      console.log(this.uid);
      this.getTiers();
      this.getTiersCatalog();
    },
    methods: {
      async continue1() {
        if (parseInt(this.loadTier.id) == 0) {
          this.notify('error', 'Please select your tier');
          return;
        }
        if (!this.vEmpty(this.farmName, 'Farm Name')) {
          return;
        }

        if (this.farmName.length > 50) {
          this.notify('error', 'Too many characters for a farm name');
          return;
        }

        if (!this.vEmpty(this.storage_path, 'Storage Path')) {
          return;
        }

        if (this.storage_path.length > 255) {
          this.notify('error', 'Storage path is too large');
          return;
        }


        //Buscar utilizando  this.loadTier.tier en el objeto this.tiers para obtener el campo package_id y asi obtener sus features
        const selectedTier = this.tiers.find(tier => tier.package_id === this.loadTier.tier);

        let features = [];
        if (selectedTier) {
          features = selectedTier.features;
          console.log('Features:', features);
        } else {
          console.error('Tier not found');
        }



        const newFarm = {
          user_id: this.userId,
          name: this.farmName,
          storage_path: this.storage_path,
          triedId: this.loadTier.id,
          packageId: this.loadTier.tier,
          features: JSON.stringify(features)
        };

        console.log('New Farm:', newFarm);

        this.openLoading();

        try {
          await axios.post(`${API}/manager-register-farm-tier`, newFarm);
          this.notify('success', 'Tier was connected successfully');
          this.showStationDialog = false;
          this.getTiers();
        } catch (error) {
          console.error('Error registering tier:', error.response ? error.response.data.error : error.message);
          this.notify('error', error.response ? error.response.data.error : 'Failed to register tier');
        } finally {
          this.closeLoading();
        }
      },
       formatDate(date) {
        return moment(date).utc().format("YYYY-MM-DD");
      },
      // Connect Tier function
      async showConnectTier(item) {
        console.log(item);
        this.loadTier = item;
        this.showStationDialog = true;

      },
      // Add Tier function
      async processPayment(token) {

        this.openLoading();

        console.log('ENVIADO A BACKEND')

        try {
          const response = await axios.post(`${API}/manager-make-payment`, {
            user: this.userId,
            tier: `Payment for ${this.selectedTierTime} ${this.selectedTierName}`,
            tierId: this.selectedTierId,
            tierMode: this.selectedTierTime
          });

          // Manejar la respuesta del backend (puede mostrar un mensaje de éxito al usuario)
          console.log(response);
          this.closeLoading();
          this.notify('success', 'Tier added successfully');
          setTimeout(() => {
            this.addTier = false;
            this.getTiers();
          }, 1000);
        } catch (error) {
          console.log('ERROR EN BACKEND', error)
          this.closeLoading();
          // Manejar errores del backend (puede mostrar un mensaje de error al usuario)
          console.error(error.response.data);
        }
      },
      // Open Add Tier Dialog
      openAddTier() {
        this.addTier = true;
      },
      backToTier() {
        this.selectedOn = false;
        this.tierOn = false;
        this.tiersOn = true;
        this.selectedTierId = null;
        this.selectedTierCost = null;
        this.selectedTierName = null;
        this.selectedTierTime = null;
      },
      selectTier(id, cost, name, time) {
        this.selectedTierId = id;
        this.selectedTierCost = cost;
        this.selectedTierName = name;
        this.selectedTierTime = time;
        this.tiersOn = false;
        this.tierOn = false;
        this.selectedOn = true;
      },
      async getTiersCatalog() {
        this.tiers = [];
        await axios
          .get(`${API}/getTiers`)
          .then((data) => {
            let response = data.data;
            if (response.length > 0) {
              this.tiers = response;
              console.log(this.tiers);
            }
          })
          .catch(function (error) {
            console.log("entra..................");
            console.log(error);
          });
      },
      async editTier() {
        console.log(this.formattedStartDate, this.formattedEndDate);
        // Validar que las fechas no estén vacías
        if (!this.formattedStartDate || !this.formattedEndDate) {
          this.notify("error", "Please select start and end date");
          return;
        }
        // Validar que la fecha de inicio sea menor a la fecha de fin
        if (new Date(this.formattedStartDate) > new Date(this.formattedEndDate)) {
          this.notify("error", "Start date must be less than end date");
          return;
        }
        //validar que formattedStartDate sea mayor a la fecha actual
        if (new Date(this.formattedEndDate) < new Date()) {
          this.notify("error", "End date must be greater than current date");
          return;
        }

        // Realizar la solicitud al servidor para editar el tier
        await axios
          .put(`${API}/manager-edit-tier/${this.selectedTierdits.id}`, {
            startDate: this.formattedStartDate,
            endDate: this.formattedEndDate,
          })
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Tier edited:", response.data);
            this.notify("success", "Tier dates edited successfully");
            this.showEditDialog = false;
            this.getTiers();
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify("error", error.response ? error.response.data.error : 'Failed to edit tier');
          });
      },
      async deleteTier() {
        await axios
          .delete(`${API}/manager-delete-tier/${this.selectedTierdits.id}`)
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Tier deleted:", response.data);
            this.notify("success", "Tier deleted successfully");
            this.showDeleteTierDialog = false;
            this.getTiers();
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify("error", error.response ? error.response.data.error : 'Failed to delete tier');
          });
      },
      showDeleteTier(item) {
        this.selectedTierdits = item;
        this.showDeleteTierDialog = true;
      },

      showEditTier(item) {
        this.selectedTierdits = item;
        this.showEditDialog = true;
        this.startDateEdit = item.startDate;
        this.endDateEdit = item.endDate;
      },

      async getTiers() {
        this.userTiers = [];
        // Realizar la solicitud al servidor para obtener los hijos del usuario seleccionado
        await axios
          .get(`${API}/manager-get-user-tiers/${this.userId}`)
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Childs:", response.data);
            this.userTiers = response.data;
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify("error", error.response.data.error);
          });
      },
    },
  };
</script>