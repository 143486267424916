<template>
  <div class="container-fluid mt-2 mb-5">
    <vs-dialog not-center v-model="delSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>sensor</b></h4>
      </template>
      <div class="con-content">
        <p>Are you sure you want to delete the sensor?</p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-12 d-flex align-items-center">
        <img src="@/assets/davis.png" width="33" class="me-2" />
        <h3 class="mb-0">Davis Data</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div
            class="col-md-4 mt-3"
            v-for="(item, index) in farms"
            :key="index"
          >
            <div class="card main-card h-100">
              <div class="card-body">
                <h5 class="text-main-gradient">{{ item.name }}</h5>
                <!-- <span class="text-muted smallText"><span
                    class="material-icons smallText me-1">folder</span>{{item.storage_path}}</span> <br> -->

                <div
                  class="card card-station mt-1"
                  v-for="(itemStation, indexStation) in item.stations.filter(
                    (station) => station.sensor_type === 1
                  )"
                  :key="indexStation"
                >
                  <div class="card-body">
                    <!-- <span class="text-main-gradient-secondary">{{itemStation.name}}</span> <br> -->
                    <!-- <span class="material-icons smallText me-1">category</span>
                    <span class="text-muted smallText">{{item.stations[0].sensor_description}} </span> -->
                    <ul class="list-group smallText">
                      <li
                        class="list-group-item"
                        v-for="(item2, index2) in itemStation.sensors"
                        :key="index2"
                        v-if="parseInt(item2.sensor_type) == 1"
                      >
                        <template v-if="parseInt(item2.last_status) == 0">
                          <span
                            class="smallText d-flex align-items-center justify-content-left"
                          >
                            <div class="circle-gray me-1"></div>
                            {{ item2.name }}, id: {{ item2.id }}, config:
                            {{ item2.config }}
                          </span>

                          <!-- <a class="text-link" @click="
                              turnDavis(
                                item2.id,
                                1,
                                item2.name,
                                item2.config,
                                itemStation.config,
                                item.storage_path
                              )
                            ">Turn on</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)"
                            >Last measure</a
                          >
                          <!-- <a class="text-link ms-2" @click="delete_davis(item2)"
                            v-if="hasPermission('sensor',user.permissions)">Delete</a> -->
                        </template>
                        <template v-else>
                          <span
                            class="smallText d-flex align-items-center justify-content-left"
                          >
                            <div class="circle me-1"></div>
                            {{ item2.name }}, id: {{ item2.id }}, config:
                            {{ item2.config }}
                          </span>
                          <!-- <a class="text-link" @click="
                              turnDavis(item2.id, 0, item2.name, item2.config,  itemStation.config, item.storage_path)
                            ">Turn off</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)"
                            >Last measure</a
                          >
                          <!-- <a class="text-link ms-2" @click="delete_davis(item2)"
                            v-if="hasPermission('sensor',user.permissions)">Delete</a> -->
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="col-md-8" v-if="lastMeasure && lastMeasure.length > 0">
              <h3 class="text-main-gradient-secondary">Last measure</h3>
              <p>
                <strong>Sensor:</strong> {{ selectedSensor.name }}<br />
                <strong>Config/Serial number:</strong> {{ selectedSensor.config
                }}<br />
              </p>
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-group smallText">
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >1.-Observation_time: </strong
                      >{{
                        moment(lastMeasure[0].observation_time)
                          .subtract(7, "days")
                          .format("MMM Do YYYY HH:m:ss")
                      }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >2.-Barometer: </strong
                      >{{ lastMeasure[0].bar }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >3.- Relative_humidity: </strong
                      >{{ lastMeasure[0].hum_out }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >4.- Temp_f: </strong
                      >{{ lastMeasure[0].temp_out }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >5.- Wind_dir: </strong
                      >{{ lastMeasure[0].wind_dir }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >6.- Wind_mph: </strong
                      >{{ lastMeasure[0].wind_speed }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >7.- Et_day: </strong
                      >{{ lastMeasure[0].et_day }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >8.- Rain_day_in: </strong
                      >{{ lastMeasure[0].rain_day_in }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >9.- Solar_radiation: </strong
                      >{{ lastMeasure[0].solar_rad }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >10.- Ultraviolet_rays: </strong
                      >{{ lastMeasure[0].uv }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >11.- Temperature_probe: </strong
                      >{{ lastMeasure[0].temperature_probe }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >12.- Soil Moisture SM150T_1: </strong
                      >{{ lastMeasure[0].m1501 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >13.- Soil Moisture SM150T_2: </strong
                      >{{ lastMeasure[0].m1502 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >14.- Soil Moisture SM150T_3: </strong
                      >{{ lastMeasure[0].m1503 }}
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul class="list-group smallText">
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >15.- Soil Moisture SM150T_4: </strong
                      >{{ lastMeasure[0].m1504 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >16.- Soil Moisture SM150T_5: </strong
                      >{{ lastMeasure[0].m1505 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >17.- Soil Moisture SM150T_6: </strong
                      >{{ lastMeasure[0].m1506 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >18.- Soil Moisture SM150T_7: </strong
                      >{{ lastMeasure[0].m1507 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >19.- Soil Moisture SM150T_8: </strong
                      >{{ lastMeasure[0].m1508 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >20.- Soil Moisture SM150T_9: </strong
                      >{{ lastMeasure[0].m1509 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >21.- Soil Moisture SM150T_10: </strong
                      >{{ lastMeasure[0].m1510 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >22.- Soil_Moisture_SM150: </strong
                      >{{ lastMeasure[0].soil_moisture_sm150 }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >23.- Normally Closed: </strong
                      >{{ lastMeasure[0].normally_closed }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >24.- Et_yesterday: </strong
                      >{{ lastMeasure[0].etPast }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >25.- Rain_weekly_in: </strong
                      >{{ lastMeasure[0].weekly_rain }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >26.- Rain_monthly_in: </strong
                      >{{ lastMeasure[0].monthly_rain }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >27.- Rain_yearly_in: </strong
                      >{{ lastMeasure[0].yearly_rain }}
                    </li>
                  </ul>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
import SetInterval from "set-interval";
import moment from "moment"; // Importa Moment.js

var config = require("@/config/api.json");
const API = config.api;
export default {
  name: "SoilData",
  props: {
    itemData: Object,
    userId: {
        type: Number,
        required: true,
      },
      uid: {
        type: String,
        required: true,
      },
  },
  data() {
    return {
      user: null,
      farms: [],
      active: false,
      options: [],
      lastMeasure: [],
      selectedSensor: [],
      observations: [],
      delSensor: false,
      sampleTime: 1200000,
    };
  },

  async mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
      await this.getFarms();
      await this.getSensors();
      console.log("sensors", this.sensors);
      // this.verifyDavis();
    }
  },
  methods: {
    async getSensors() {
      await axios.get(`${API}/api-sensors`).then((response) => {
        this.sensors = response.data;
      });
    },

    storeRain(uid, stationId, rain) {
      let obj = { uid: uid, stationId: stationId, rain };
      localStorage.setItem(`r-${stationId}`, JSON.stringify(obj));
    },
    async getWeatherDataDavis(stationId, config, sensorId, name, path) {
      var vm = this;
      console.log("Weather Davis", stationId, sensorId);
      let davisConnection = JSON.parse(config);
      this.observations = [];
      var weekly_rain = 0;
      var monthly_rain = 0;
      var yearly_rain = 0;
      var hasRemotes = false;

      // await axios
      //   .get(`https://quantumsync.org:3000/api/rain_history/getRainData?uid=${vm.user}&davisId=${api}`)
      //   .then(function (response) {
      //     console.log('RAIN FROM SERVER:::::::::::::::::')
      //     console.log(response.data);
      //     let data = response.data
      //     console.log(data[0].weekly_rain, data[0].monthly_rain, data[0].total_rain)
      //     if (data[0].weekly_rain != undefined) {
      //       console.log('VALORES ASIGNADOS 1')
      //       weekly_rain = data[0].weekly_rain.toFixed(3)
      //     }
      //     if (data[0].monthly_rain != undefined) {
      //       console.log('VALORES ASIGNADOS 2')
      //       monthly_rain = data[0].monthly_rain.toFixed(3)
      //     }
      //     if (data[0].total_rain != undefined) {
      //       console.log('VALORES ASIGNADOS 3')
      //       yearly_rain = data[0].total_rain.toFixed(3)
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      await axios
        .post(`${API}/getDavisData`, {
          apiKey: davisConnection.apiKey,
          apiSecret: davisConnection.apiSecret,
          stationId: stationId,
        })
        .then((response) => {
          vm.observations = response.data.sensors[0].data[0];

          let mainData = response.data.sensors;

          let totalData = response.data.sensors.length;
          if (totalData > 1) {
            mainData.forEach(function (element) {
              hasRemotes = true;

              if (vm.observations["etPast"] === undefined) {
                vm.observations["etPast"] = 0;
              }
              if (element.etPast) {
                vm.observations["etPast"] = element.etPast;
              }

              if (
                element.data_structure_type == 6 ||
                element.data_structure_type == 2
              ) {
                console.log(
                  "---------------------SENSOR 2 and 6---------------------"
                );
                vm.observations["bar"] = element.data[0]["bar"];
                vm.observations["hum_out"] = element.data[0]["hum_out"];
                vm.observations["temp_out"] = element.data[0]["temp_out"];
                vm.observations["wind_dir"] = element.data[0]["wind_dir"];
                vm.observations["wind_speed"] = element.data[0]["wind_speed"];
                vm.observations["et_day"] = element.data[0]["et_day"];
                vm.observations["rain_day_in"] = element.data[0]["rain_day_in"];

                vm.observations["weekly_rain"] = weekly_rain;
                vm.observations["monthly_rain"] = monthly_rain;
                vm.observations["yearly_rain"] = yearly_rain;

                if (element.data[0]["rain_day_in"] !== undefined) {
                  vm.storeRain(
                    vm.user,
                    stationId,
                    element.data[0]["rain_day_in"]
                  );
                }

                vm.observations["solar_rad"] = element.data[0]["solar_rad"];
                if (!element.data[0]["uv"]) {
                  vm.observations["uv"] = 0.0;
                }
                if (vm.observations["Normally_Closed"] > 0) {
                  vm.observations["Normally_Closed"] = 1;
                } else {
                  vm.observations["Normally_Closed"] = 0;
                }
              } else {
                console.log("entra ELSE...");
                vm.sensors.forEach((sensor) => {
                  if (sensor.id == element.sensor_type) {
                    var str = sensor.name;
                    var sensor_fixed = str.split(" ").join("_");
                    vm.observations[sensor_fixed] =
                      element.data[0][sensor.field];
                    if (vm.observations["Soil_Moisture_SM150"]) {
                      vm.observations["Soil_Moisture_SM150"] = 0.0;
                    }
                    if (!vm.observations["Normally_Closed"] > 0) {
                      vm.observations["Normally_Closed"] = 1;
                    } else {
                      vm.observations["Normally_Closed"] = 0;
                    }

                    // Crear un objeto 'sensors' si no existe
                    if (!vm.observations.sensors) {
                      vm.observations.sensors = [];
                    }

                    vm.observations.sensors.push({
                      name: sensor_fixed,
                      value: element.data[0][sensor.field],
                    });
                  }
                });
              }
            });
          } else {
            vm.observations["weekly_rain"] = weekly_rain;
            vm.observations["monthly_rain"] = monthly_rain;
            vm.observations["yearly_rain"] = yearly_rain;
            // vm.storeRain(vm.user, api, vm.observations.rain_day_in);
            hasRemotes = false;
          }

          if (!vm.observations["bar"]) {
            vm.observations["bar"] = 0.0;
          }
          if (!vm.observations["hum_out"]) {
            vm.observations["hum_out"] = 0.0;
          }
          if (!vm.observations["temp_out"]) {
            vm.observations["temp_out"] = 0.0;
          }
          if (!vm.observations["wind_dir"]) {
            vm.observations["wind_dir"] = 0.0;
          }
          if (!vm.observations["wind_speed"]) {
            vm.observations["wind_speed"] = 0.0;
          }
          if (!vm.observations["et_day"]) {
            vm.observations["et_day"] = 0.0;
          }
          if (!vm.observations["rain_day_in"]) {
            vm.observations["rain_day_in"] = 0.0;
          }
          if (!vm.observations["solar_rad"]) {
            vm.observations["solar_rad"] = 0.0;
          }
          if (!vm.observations["uv"]) {
            vm.observations["uv"] = 0.0;
          }
          if (!vm.observations["temperature_probe"]) {
            vm.observations["temperature_probe"] = 0.0;
          }
          if (!vm.observations["etPast"]) {
            vm.observations["etPast"] = 0.0;
          }
          if (vm.observations["Soil_Moisture_SM150"]) {
            vm.observations["Soil_Moisture_SM150"] = 0.0;
          }
          vm.observations["sensorId"] = sensorId;
          vm.observations["sensorId"] = sensorId;
          vm.observations["user"] = vm.user;
          vm.observations["path"] = path;
          vm.observations["observation_time"] = moment
            .unix(response.data.generated_at)
            .format("lll");
        })
        .then(() => {
          let davis = vm.observations;
          var iccDb = [];
          console.log(
            "LIMPIAR**********************************************************"
          );
          iccDb["sensor_id"] = sensorId;
          iccDb["stationId"] = stationId;
          iccDb["observation_time"] = vm.observations["observation_time"];
          iccDb["bar"] = vm.observations["bar"];
          iccDb["hum_out"] = vm.observations["hum_out"];
          iccDb["temp_out"] = vm.observations["temp_out"];
          iccDb["wind_dir"] = vm.observations["wind_dir"];
          iccDb["wind_speed"] = vm.observations["wind_speed"];
          iccDb["et_day"] = vm.observations["et_day"];
          iccDb["rain_day_in"] = vm.observations["rain_day_in"];
          iccDb["solar_rad"] = vm.observations["solar_rad"];
          iccDb["uv"] = vm.observations["uv"];
          iccDb["temperature_probe"] = vm.observations["temperature_probe"];

          let mSensors = [];
          mSensors = vm.observations.sensors;
          if (mSensors) {
            if (!mSensors[0]) {
              iccDb["m1501"] = 0;
            } else {
              iccDb["m1501"] = mSensors[0].value;
            }
            if (!mSensors[1]) {
              iccDb["m1502"] = 0;
            } else {
              iccDb["m1502"] = mSensors[1].value;
            }
            if (!mSensors[2]) {
              iccDb["m1503"] = 0;
            } else {
              iccDb["m1503"] = mSensors[2].value;
            }
            if (!mSensors[3]) {
              iccDb["m1504"] = 0;
            } else {
              iccDb["m1504"] = mSensors[3].value;
            }
            if (!mSensors[4]) {
              iccDb["m1505"] = 0;
            } else {
              iccDb["m1505"] = mSensors[4].value;
            }
            if (!mSensors[5]) {
              iccDb["m1506"] = 0;
            } else {
              iccDb["m1506"] = mSensors[5].value;
            }
            if (!mSensors[6]) {
              iccDb["m1507"] = 0;
            } else {
              iccDb["m1507"] = mSensors[6].value;
            }
            if (!mSensors[7]) {
              iccDb["m1508"] = 0;
            } else {
              iccDb["m1508"] = mSensors[7].value;
            }
            if (!mSensors[8]) {
              iccDb["m1509"] = 0;
            } else {
              iccDb["m1509"] = mSensors[8].value;
            }
            if (!mSensors[9]) {
              iccDb["m15010"] = 0;
            } else {
              iccDb["m15010"] = mSensors[9].value;
            }
          } else {
            iccDb["m1501"] = "0";
            iccDb["m1502"] = "0";
            iccDb["m1503"] = "0";
            iccDb["m1504"] = "0";
            iccDb["m1505"] = "0";
            iccDb["m1506"] = "0";
            iccDb["m1507"] = "0";
            iccDb["m1508"] = "0";
            iccDb["m1509"] = "0";
            iccDb["m15010"] = "0";
          }

          if (!vm.observations["Soil_Moisture_SM150"]) {
            iccDb["soil_moisture_sm150"] = 0;
          } else {
            iccDb["soil_moisture_sm150"] =
              vm.observations["soil_moisture_sm150"];
          }
          if (!vm.observations["Soil_Moisture_SM150T"]) {
            iccDb["soil_Moisture_SM150T"] = 0;
          } else {
            iccDb["soil_Moisture_SM150T"] =
              vm.observations["Soil_Moisture_SM150T"];
          }
          iccDb["normally_closed"] = vm.observations["Normally_Closed"];
          iccDb["etPast"] = vm.observations["etPast"];
          iccDb["weekly_rain"] = vm.observations["weekly_rain"];
          iccDb["monthly_rain"] = vm.observations["monthly_rain"];
          iccDb["yearly_rain"] = vm.observations["yearly_rain"];
          iccDb["path"] = path;
          iccDb["sensorId"] = sensorId;
          iccDb["sensorName"] = name;

          console.log("ICC", iccDb);

          // ipcRenderer.send("createDavisFile", iccDb);
        })
        .catch(function (error) {
          console.log("catch!!");
          console.log(error);
          // vm.displayError(`Error ${error}`);
          return;
        });
    },

    delete_davis(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      this.delSensor = true;
    },

    deleteSensor() {
      axios
        .post(`${API}/delete-davis-sensor`, {
          sensor_id: this.selectedSensor.id,
          station_id: this.selectedSensor.station_id,
        })
        .then((response) => {
          this.notify("success", "Sensor deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.delSensor = false;
          this.$emit("davis-deleted", true);
        });
    },

    async last_mesure(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      try {
        const response = await axios.get(
          `${API}/davis-last-measure/${this.selectedSensor.id}`
        );
        setTimeout(() => {
          this.lastMeasure = response.data;
        }, 333);
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch last measure, there is no data to display yet.");
      }
    },

    async turnDavis(id, value, name, stationId, station_config, path) {
      try {
        const response = await axios.post(`${API}/turnDavis`, {
          id: id,
        });

        if (parseInt(value) == 0) {
          SetInterval.clear(`davis${id}`);
          this.notify("success", `Stopped ${name}`);
          this.$emit("davis-turned", false);
        } else {
          SetInterval.clear(`davis${id}`);
          this.getWeatherDataDavis(stationId, station_config, id, name, path);
          SetInterval.start(
            () => {
              this.getWeatherDataDavis(
                stationId,
                station_config,
                id,
                name,
                path
              );
            },
            this.sampleTime,
            `davis${id}`
          );
          this.notify("success", `Started ${name}`);
          this.$emit("davis-turned", true);
        }
        this.getFarms();
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Error turning on/off sensor");
      }
    },

    async getFarms() {
      this.farms = [];
      try {
        const response = await axios.get(
          `${API}/getFarmsByOwnerSingle/${this.userId}/${this.itemData.id}`
        );
        this.farms = response.data;
        this.sampleTime = parseInt(this.farms[0].sampleTime * 60000);
        console.log("GET FARS: ", this.farms, "sampletime", this.sampleTime);
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },
    async verifyDavis() {
      return;
      if (this.farms && this.farms.length > 0) {
        this.farms.forEach((farm) => {
          if (farm.stations && farm.stations.length > 0) {
            const soilStations = farm.stations.filter(
              (station) => station.sensor_type === 1
            );
            if (soilStations.length > 0) {
              console.log("Stations", soilStations);
              soilStations.forEach((station) => {
                if (station.sensors && station.sensors.length > 0) {
                  const activeSensors = station.sensors.filter(
                    (sensor) => sensor.last_status === 1
                  );
                  if (activeSensors.length > 0) {
                    activeSensors.forEach((element) => {
                      console.log(element);
                      SetInterval.start(
                        () => {
                          this.getWeatherDataDavis(
                            element.config,
                            farm.storage_path,
                            element.id,
                            station.name
                          );
                        },
                        60000,
                        `soilScout${element.id}`
                      );
                      this.getWeatherDataDavis(
                        element.config,
                        farm.storage_path,
                        element.id,
                        station.name
                      );
                    });
                  }
                }
              });
            }
          }
        });
      }
    },
  },
};
</script>
