<template>
  <div>
    <h5 class="text-main-gradient-secondary"><span class="material-icons ">sensors</span> Current sensors</h5>
    <p>Overview of total registered sensors</p>
    <apexchart ref="chart" type="pie" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
var config = require("@/config/api.json");
const API = config.api;

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      currentSensors: [],
      series: [1,2,3,4,5], // Inicialmente vacío, se llenará con sensor_count
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], // Inicialmente vacío, se llenará con sensor_type
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.managerCurrentSensors();
  },
  methods: {
    async managerCurrentSensors() {
      try {
        const response = await axios.get(`${API}/manager-get-sensors-chart`);
        this.currentSensors = response.data;
        const labels = [];
        const series = [];
        this.currentSensors.forEach(sensor => {
          labels.push(sensor.sensor_type);
          series.push(sensor.sensor_count);
        });
        this.$refs.chart.updateOptions({
          labels: labels,
          series: series
        });
      } catch (error) {
        this.notify('error', 'There are no sensors to show');
      }
    }
  }
};
</script>